import { useSanityClient } from '/machinery/SanityClient'
import { routes, determineDocumentPath } from '/routes'
import { LinkBlock } from '/components/buildingBlocks/Link'
import { Image } from '/components/Image'
import { ButtonCircleDark } from '/components/buildingBlocks/ButtonCircle'
import { IconRightArrow } from '/components/buildingBlocks/Icon'
import { useKeenSlider } from 'keen-slider/react'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'

import styles from './Slider.css'
import 'keen-slider/keen-slider.min.css'

export function Slider({ items }) {
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl)
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: isViewportXl ? 2.5 : 1.2,
      spacing: isViewportXl ? 70 : 40
    }
  })

  return (
    <div className={styles.component}>
      <div ref={sliderRef} className={cx(styles.items, 'keen-slider')}>
        {items.map((article, index) => (
          <div className={cx(styles.card, 'keen-slider__slide')} key={index}>
            <SliderCard
              title={article?.title}
              image={article?.image}
              slug={article?.slug}
              contributors={article?.contributors}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export function SliderCard({ image, title, slug, contributors }) {
  const { imageBuilder } = useSanityClient()
  const link = determineDocumentPath({ document: { _type: 'article', slug: { current: slug } }, routes })
  const hasImage = image?.asset?.url

  return (
    // eslint-disable-next-line @kaliber/layout-class-name
    <LinkBlock to={link} layoutClassName={styles.componentSliderCard}>
      <div className={styles.coverImage}>
        {hasImage && (
          <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.imageLayout} />
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.titleAndInfo}>
          <h3 className={styles.title}>{title}</h3>
          {contributors && (
            <div className={styles.info}>
              {contributors.map((x, index) => (
                <span key={x._id}>
                  {x.firstName}, {x.role} {index < contributors.length - 1 ? '• ' : ''}
                </span>
              ))}
            </div>
          )}
        </div>
        <ButtonCircleDark layoutClassName={styles.circleButtonLayout}>
          <IconRightArrow />
        </ButtonCircleDark>
      </div>
    </LinkBlock>
  )
}
