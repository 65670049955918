import { LinkButton, LinkTextArrow } from '/components/buildingBlocks/Link'
import { ContentContainer } from '/components/home/pageOnly/ContentContainer'
import { Icon } from '/components/buildingBlocks/Icon'

import martin from '/components/expertise/images/martin_zonder_bril.png'
import gertJan from '/components/expertise/images/gert_zonder_bril.png'
import ronald from '/components/expertise/images/ronald_zonder_bril.png'
import luke from '/components/expertise/images/luke_zonder_bril.png'
import bril from '/components/expertise/images/bril.png'
import handPointer from '/components/expertise/images/hand-pointer.raw.svg'

import styles from './ContactBlock.css'

export function ContactBlock({ name }) {
  const innerRef = React.useRef(null)

  return (
    <div className={styles.component}>
      <ContentContainer width='lg'>
        <div className={styles.inner} ref={innerRef}>
          <Contact layoutClassName={styles.contactLayout} job={getContactPerson(name).job} name={getContactPerson(name).name} />
          {name && <ContactWithGlasses layoutClassName={styles.imageLayout} image={getContactPerson(name).image} />}
          <Icon layoutClassName={styles.pointerLayout} icon={handPointer} />
        </div>
      </ContentContainer>
    </div>
  )
}

function ContactWithGlasses({ image, layoutClassName }) {
  return (
    <div className={cx(styles.componentContactWithGlasses, layoutClassName)}>
      <img className={styles.image} rol='presentation' src={image} alt='Contact persoon van Kaliber' />
      {/* <img className={styles.bril} src={bril} rol='presentation' alt='bril' /> */}
    </div>
  )
}

function Contact({ name, job, layoutClassName }) {
  return (
    <div className={cx(styles.componentContact, layoutClassName)}>
      <h3 className={styles.heading}>Wil jij weten hoe we jou helpen bij het aantrekken van het juiste talent?</h3>
      <p className={styles.text}>Neem contact op met {name}, {job}</p>
      <LinkButton
        layoutClassName={styles.buttonLayout}
        to='tel:+31302876400'
        datax='link-to-call'
        dataxcontext='contact-block'
      >
        Bel 030 287 64 00
      </LinkButton>
      <LinkTextArrow
        to='mailto:hallo@kaliber.net'
        layoutClassName={styles.linkLayout}
        datax='link-to-email'
        dataxcontext='contact-block'
      >
        Mail naar hallo@kaliber.net
      </LinkTextArrow>
    </div>
  )
}

export function getContactPerson(name) {
  const images = [
    { image: martin, value: 'martin', name: 'Martin', job: 'Business director' },
    { image: gertJan, value: 'gertJan', name: 'Gert-Jan', job: 'Client services manager' },
    { image: ronald, value: 'ronald', name: 'Ronald', job: 'Eigenaar/directeur' },
    { image: luke, value: 'luke', name: 'Luke', job: 'Client services manager' }
  ]
  return images.find((x) => x.value === name)
}
