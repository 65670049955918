import { Link as RouterLink } from '@reach/router'
import { Svg } from '/components/buildingBlocks/Svg'
import cx from 'classnames'
import styles from './Link.css'

export { LinkBase as Link }

export function LinkExternal({ children, to, dataX = undefined }) {
  return (
    <LinkBase {...{ children, to }} data-x={dataX} className={styles.componentExternal} />
  )
}

export function LinkText({ to, children, target = undefined, rel = undefined, onClick = undefined }) {
  return (
    <LinkBase
      className={styles.componentText}
      {...{ to, children, target, rel, onClick }}
    />
  )
}

export function LinkTextUnderline({ to, children, target = undefined, rel = undefined, onClick = undefined }) {
  return (
    <LinkBase
      className={styles.componentTextUnderline}
      {...{ to, children, target, rel, onClick }}
    />
  )
}

export function LinkTextArrow({ to, children, datax = undefined, dataxcontext = undefined, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return (
    <LinkTextArrowBase className={layoutClassName} {...{ to, target, rel, onClick, children, datax, dataxcontext }} />
  )
}

export function LinkTextArrowOnBackground({ to, children, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return (
    <LinkTextArrowBase className={cx(styles.componentTextArrowOnBackground, layoutClassName)} {...{ to, target, rel, onClick, children }} />
  )
}

function LinkTextArrowBase({ to, children, target = undefined, rel = undefined, onClick = undefined, className = undefined }) {
  return (
    <LinkBase className={cx(styles.componentTextArrowBase, className)} {...{ to, target, rel, onClick }}>
      <span className={styles.text}>{children} <span className={styles.arrow}>&#8594;</span></span>
    </LinkBase>
  )
}

export function LinkBlock({ to, children, target = undefined, rel = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentBlock, layoutClassName)}
      {...{ to, children, target, rel }}
    />
  )
}

export function LinkBorderTransition({ to, children, target, rel, color }) {
  return (
    <LinkBase
      className={styles.componentBorderTransition}
      {...{ to, target, rel }}
    >
      {children}
      <span className={styles.border} style={{ backgroundColor: color }} />
    </LinkBase>
  )
}

export function LinkArrowBorderTransition({ to, children, target = undefined, rel = undefined, color = undefined }) {
  return (
    <LinkBorderTransition {...{ to, target, rel, color }}>
      <span className={styles.componentArrowBorderTransition}>{children} <span className={styles.arrow}>&#8594;</span></span>
    </LinkBorderTransition>
  )
}

export function LinkButton({ to, children, datax = undefined, dataxcontext = undefined, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentButton, layoutClassName)}
      {...{ to, target, rel, onClick, datax, dataxcontext }}
    >
      <span className={styles.buttonText}>{children}</span>
    </LinkBase>
  )
}

export function LinkButtonWhite({ to, children, datax = undefined, dataxcontext = undefined, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentButton, styles.componentButtonWhite, layoutClassName)}
      {...{ to, target, rel, onClick, datax, dataxcontext }}
    >
      <span className={styles.buttonText}>{children}</span>
    </LinkBase>
  )
}

export function LinkButtonYellow({ to, children, target = undefined, rel = undefined, onClick = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentButton, styles.componentButtonYellow, layoutClassName)}
      {...{ to, target, rel, onClick }}
    >
      <span className={styles.buttonText}>{children}</span>
    </LinkBase>
  )
}

function LinkBase({ to, children, className, datax = undefined, dataxcontext = undefined, target = undefined, rel = undefined, onClick = undefined }) {
  if (to) {
    const protocols = ['http', 'tel', 'mailto']
    const hasProtocol = protocols.some(x => to.startsWith(x))
    const safeRel = target === '_blank' ? `${rel || ''} noopener noreferrer` : rel // https://web.dev/external-anchors-use-rel-noopener/
    const ElBase = hasProtocol ? ABase : RouterLink
    return <ElBase rel={safeRel} className={cx(styles.componentBase, className)} {...{ to, children, target, onClick, datax, dataxcontext }} />
  } else {
    return <PseudoBase className={cx(styles.componentBase, className)} {...{ rel, children, onClick, target }} />
  }
}

function ABase({ to, children, className, target, rel, onClick, datax = undefined, dataxcontext = undefined }) {
  return <a href={to} data-x={datax} data-x-context={dataxcontext} {...{ className, target, rel, onClick }}>{children}</a>
}

function PseudoBase({ children, className, target, rel, onClick }) {
  return <span {...{ className, target, rel, onClick }}>{children}</span>
}
